import image from '../assets/LatestBlog.png'
import writterImage from '../assets/Writter-image.png'
import { v4 as uuidv4 } from 'uuid';
const LatestBlogsData = [
    {   id:uuidv4(),
        image:image,
        title:"An Horse an powerful story",
        description:"From cozy couches to stylish tables, find the furniture thatturns your house into a home. Discover the art of comfort.",
        time:"9Min",
        writter:{
            wirtter_image:writterImage,
            wiritter_name:'Arience McCoy'
        }
    },
    {   id:uuidv4(),
        image:image,
        title:"An Horse an powerful story",
        description:"From cozy couches to stylish tables, find the furniture thatturns your house into a home. Discover the art of comfort.",
        time:"9Min",
        writter:{
            wirtter_image:writterImage,
            wiritter_name:'Arience McCoy'
        }
    },
    {   id:uuidv4(),
        image:image,
        title:"An Horse an powerful story",
        description:"From cozy couches to stylish tables, find the furniture thatturns your house into a home. Discover the art of comfort.",
        time:"9Min",
        writter:{
            wirtter_image:writterImage,
            wiritter_name:'Arience McCoy'
        }
    },
    {   id:uuidv4(),
        image:image,
        title:"An Horse an powerful story",
        description:"From cozy couches to stylish tables, find the furniture thatturns your house into a home. Discover the art of comfort.",
        time:"9Min",
        writter:{
            wirtter_image:writterImage,
            wiritter_name:'Arience McCoy'
        }
    },
    {   id:uuidv4(),
        image:image,
        title:"An Horse an powerful story",
        description:"From cozy couches to stylish tables, find the furniture thatturns your house into a home. Discover the art of comfort.",
        time:"9Min",
        writter:{
            wirtter_image:writterImage,
            wiritter_name:'Arience McCoy'
        }
    },
    

]
export default LatestBlogsData